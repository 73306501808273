<template>
  <div class="page-content">
    <div style="position: absolute; left: 30px; top: 30px; z-index: 99">
      <div class="btn-group">
        <button class="btn btn-grey" @click="btnClick(-1)">
          工业区1({{ geoData.all.length }})
        </button>
        <button class="btn btn-danger" @click="btnClick(0)">
          工业区2({{ geoData.geoRed.length }})
        </button>
        <button class="btn btn-warning" @click="btnClick(1)">
          工业区3({{ geoData.geoYellow.length }})
        </button>
        <button class="btn btn-green" @click="btnClick(2)">
          红林村({{ geoData.geoGreen.length }})
        </button>
      </div>
    </div>
    <div
      id="container"
      :center="{ point }"
      :style="{ height: windowHeight }"
      class="map"
      tabindex="0"
    ></div>
  </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
import Camera from "./Camera.vue"; //导入企业信息编辑跳出框
// import { businessApi } from "@/api/system/businessApi";
//防盗水印
// import watermark from "../plugins/watermark/index";
export default {
  data() {
    return {
      AMap: null,
      map: null,
      Cameradata: [
        {
          address: "浙江省宁波市鄞州区东钱湖镇松涛西路",
          id: "3a02743c-6458-f3b3-52f9-7de63e9bdb1c",
          location: "121.63201,29.809058",
          name: "测试企业",
          safetyLevel: 2,
          state: 2,
        },
        {
          address: "兴建路3号进去左二",
          id: "3a01a225-55d2-fe4b-e8fd-4b348891eb6f",
          location: "121.62746,29.810536",
          name: "华创电子一",
          safetyLevel: 0,
          state: 2,
        },
        {
          address: "兴建路3号进去左一",
          id: "3a01a225-567f-a802-b2a4-6b015686e3de",
          location: "121.62746,29.815536",
          name: "华创电子二",
          safetyLevel: 0,
          state: 2,
        },
        {
          address: "天宝路6号",
          id: "3a01a225-56f4-b5a4-f072-9bb6d1f5246f",
          location: "121.63201,29.808058",
          name: "华创电子三",
          safetyLevel: 0,
          state: 2,
        },
        {
          address: "浙江省宁波市鄞州区东钱湖镇松涛西路",
          id: "3a02743c-6458-f3b3-52f9-7de63e9bdb1c",
          location: "121.63701,29.809358",
          name: "测试企业",
          safetyLevel: 2,
          state: 2,
        },
        {
          address: "浙江省宁波市鄞州区东钱湖镇松涛西路",
          id: "3a02743c-6458-f3b3-52f9-7de63e9bdb1c",
          location: "121.63601,29.809458",
          name: "测试企业",
          safetyLevel: 2,
          state: 2,
        },
        {
          address: "浙江省宁波市鄞州区东钱湖镇松涛西路",
          id: "3a02743c-6458-f3b3-52f9-7de63e9bdb1c",
          location: "121.65201,29.809558",
          name: "测试企业",
          safetyLevel: 2,
          state: 2,
        },
        {
          address: "浙江省宁波市鄞州区东钱湖镇松涛西路",
          id: "3a02743c-6458-f3b3-52f9-7de63e9bdb1c",
          location: "121.63401,29.809658",
          name: "测试企业",
          safetyLevel: 2,
          state: 2,
        },
        {
          address: "浙江省宁波市鄞州区东钱湖镇松涛西路",
          id: "3a02743c-6458-f3b3-52f9-7de63e9bdb1c",
          location: "121.63301,29.809758",
          name: "测试企业",
          safetyLevel: 2,
          state: 2,
        },
      ],
      // loca: null,
      cluster: null,
      markers: [],
      aMap: null,
      loca: null,
      windowHeight: "",
      point: [121.651287, 29.771813],
      geoData: {
        data: [],
        all: [],
        geoGreen: [],
        geoRed: [],
        geoYellow: [],
      },
      breath: {
        green: null,
        red: null,
        yellow: null,
      },
    };
  },
  name: "Home",
  created() {
    //如未登录则跳转到登录页面，因Home为默认打开页面，就算是刷新非Home页面也会触发
    let hasLogin = this.$store.getters.hasLogin;
    if (!hasLogin) {
      this.$router.push({ path: "/login" });
    }
    //添加防盗水印
    // let user = this.$store.getters.userInfo;
    // if (user != undefined) {
    //   watermark.set(user.username, new Date());
    // }
    this.windowHeight = document.documentElement.clientHeight - 64 + "px";
    window.openDeviceDetail = this.openDeviceDetail;
    window.openProjectDetail = this.openProjectDetail;
  },
  mounted() {
    // this.getMapLocation(2000);
    this.createMap(this.Cameradata);
    this.geoData.geoGreen = this.Cameradata.filter((item) => {
      return item.state == 2;
    });
    this.geoData.geoRed = this.Cameradata.filter((item) => {
      return item.state == 2;
    });
  },
  methods: {
    //读取所有企业点位数据
    // getMapLocation(icount) {
    //   let that = this;
    //   that.geoData.all = [];
    //   const params = {
    //     SafetyLevel: -1,
    //     MaxResultCount: icount,
    //   };
    //   businessApi.GetMapLocation(params).then((res) => {
    //     for (let i = 0; i < res.data.items.length; i++) {
    //       let element = res.data.items[i];
    //       let geo = {
    //         location: element.location,
    //         id: element.id,
    //         name: element.name,
    //         address: element.address,
    //       };

    //       // 这段代码是模拟企业状态，正式使用根据实际数据显示
    //       if (i < 10) {
    //         geo.state = 0;
    //       } else if (i < 50) {
    //         geo.state = 1;
    //       } else {
    //         geo.state = 2;
    //       }

    //       that.geoData.all.push(geo);
    //       that.geoData.data = that.geoData.all;
    //       that.geoData.geoGreen = that.geoData.all.filter((item) => {
    //         return item.state == 2;
    //       });
    //       that.geoData.geoRed = that.geoData.all.filter((item) => {
    //         return item.state == 0;
    //       });
    //       that.geoData.geoYellow = that.geoData.all.filter((item) => {
    //         return item.state == 1;
    //       });
    //     }
    //     that.createMap(that.geoData.all);
    //   });
    // },
    createMap(data) {
      let that = this;
      AMapLoader.load({
        key: "5794a2a043de715b4d37dd92f0ba64b6", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "1.4.15", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["Map3D", "AMap.MarkerClusterer"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        AMapUI: {
          // 是否加载 AMapUI，缺省不加载
          version: "1.1", // AMapUI 缺省 1.1
        },
        Loca: {
          // 是否加载 Loca， 缺省不加载
          version: "1.3.2", // Loca 版本，缺省 1.3.2
        },
      }).then((AMap) => {
        that.AMap = AMap;
        that.setMapData(data);
      });
    },
    //向地图添加数据点
    setMapData(data) {
      let that = this;
      if (that.aMap != undefined) {
        that.aMap.destroy();
      }
      that.aMap = new that.AMap.Map("container", {
        zoom: 12,
        center: [121.651287, 29.771813], //初始化地图中心点
        pitch: 40,
        mapStyle: "amap://styles/637e827d3ba2b1e2b5030a354e47f5ef",
        viewMode: "3D",
        //  features: ['bg', 'road'],
      });
      //创建 Loca 实例
      if (that.loca != undefined) {
        that.loca.destroy();
      }
      let colors = ["#ff5b57", "#f59c1a", "#08fb1a"];
      // var layer = new Loca.ScatterPointLayer({
      //     map: that.aMap
      // });
      let layer = new Loca.RoundPointLayer({
        map: that.aMap,
        fitView: true,
        eventSupport: true,
      });
      layer.setData(data, {
        lnglat: "location",
      });

      layer.setOptions({
        style: {
          // 圆形半径，单位像素
          radius: 7,
          // 填充颜色
          // color: "#08fb1a",
          color: function(obj) {
            let value = obj.value;
            switch (parseInt(value.state)) {
              case 0:
                return colors[0];
              case 1:
                return colors[1];
              default:
                return colors[2];
            }
          },
          // 描边颜色
          // borderColor: '#5DFBF9',
          // 描边宽度，单位像素
          borderWidth: 1,
          // 透明度 [0-1]
          opacity: 0.6,
        },
      });

      // let infoWin = new AMap.InfoWindow({
      //   closeWhenClickMap: true,
      // });

      layer.on("click", function(ev) {
        // 当前元素的原始数据
        let rawData = ev.rawData;
        that.openInfo(rawData);
      });

      layer.render();
    },

    //在指定位置打开信息窗体
    openInfo(rawData) {
      //构建信息窗体中显示的内容
      let info = [];
      let infoWindow;
      let zuobiao = rawData.location.split(",");
      let point = [parseFloat(zuobiao[0]), parseFloat(zuobiao[1])];
      info.push(
        '<div class="media media-sm"><i class="  p-t-10 media-left fas fa-4x fa-fw m-r-10 fa-camera-retro text-grey"></i> <div class="media-body">' +
          '<p></p><h5 class="media-heading">' +
          rawData.name +
          "</h5><p>地址：" +
          rawData.address +
          "</p>" +
          '<p class="mb-0"><a href="javascript:;" class="btn btn-sm btn-primary m-r-5" onclick="openProjectDetail(\'' +
          rawData.id +
          '\')" href="#"> 查看详细 <a/></p'
      );
      infoWindow = new AMap.InfoWindow({
        content: info.join(""), //使用默认信息窗体框样式，显示信息内容
      });
      infoWindow.open(this.aMap, point);
    },

    createMap1() {
      let that = this;
      AMapLoader.load({
        key: "5794a2a043de715b4d37dd92f0ba64b6", // key
        version: "2.0",
        plugins: ["Map3D", "AMap.MarkerClusterer"],
        Loca: {
          version: "2.0.0",
        },
      })
        .then((AMap) => {
          if (that.aMap != undefined) {
            that.aMap.destroy();
          }
          that.aMap = new AMap.Map("container", {
            zoom: 15,
            center: [121.651287, 29.771813], //初始化地图中心点
            pitch: 40,
            mapStyle: "amap://styles/637e827d3ba2b1e2b5030a354e47f5ef",
            viewMode: "3D",
          });
          //创建 Loca 实例
          if (that.loca != undefined) {
            that.loca.destroy();
          }
          that.loca = new Loca.Container({
            map: that.aMap,
            // eventSupport: true,
          });

          // 处理企业数据点
          that.addGreenPoint();
          that.addRedPoint();
          that.addYellowPoint();
          // 将企业图层加入到Loca中
          that.loca.add(that.breath.green);
          that.loca.add(that.breath.red);
          that.loca.add(that.breath.yellow);
          // 启动渲染动画
          that.loca.animate.start();
          that.aMap.on("click", (e) => {
            let p = that.breath.green.queryFeature(e.pixel.toArray());
            if (p == undefined) {
              p = that.breath.red.queryFeature(e.pixel.toArray());
            }
            if (p == undefined) {
              p = that.breath.yellow.queryFeature(e.pixel.toArray());
            }
            if (p == undefined) {
              return;
            }
            that.infoWindowMain = new AMap.InfoWindow({
              offset: new AMap.Pixel(0, -20),
            });
            that.infoWindowMain.setMap(AMap);
            that.infoWindowMain.setPosition(p.coordinates);
            that.infoWindowMain.setContent(
              `<h3>企业信息</h3>   <div> 企业名称：${p.properties.name}</div><div> 地址：   ${p.properties.address}  </div> <div style="    text-align: right;"><a onclick="openProjectDetail(' ${p.properties.id} ')" href="#"> 查看详细 <a/></div>`
            );
            that.infoWindowMain.open(that.aMap, p.coordinates);
            // console.log(p);
          });
        })
        .finally(() => {
          // state.loginBtn = false;
        });
    },

    //绿色普通点
    addGreenPoint() {
      this.geoData.geoGreen = this.geoData.all.filter((item) => {
        return item.state == 2;
      });

      let geoGreenjson = {
        type: "FeatureCollection",
        features: this.geoData.geoGreen,
      };

      // let geo = res.data.items;
      this.breath.green = new Loca.ScatterLayer({
        zIndex: 111,
        opacity: 1,
        visible: true,
        zooms: [2, 22],
        eventSupport: true,
      });
      var geo = new Loca.GeoJSONSource({
        data: geoGreenjson,
      });
      this.breath.green.setSource(geo);
      this.breath.green.setStyle({
        color: "#409940de",
        unit: "meter",
        size: [30, 30],
        borderWidth: 2,
      });
      // this.loca.add(scatter);
    },

    // 红色呼吸点
    addRedPoint() {
      this.geoData.geoRed = this.geoData.all.filter((item) => {
        return item.state == 0;
      });

      let geoRedjson = {
        type: "FeatureCollection",
        features: this.geoData.geoRed,
      };

      // 红色呼吸点
      let geoLevelF = new Loca.GeoJSONSource({
        data: geoRedjson,
      });
      this.breath.red = new Loca.ScatterLayer({
        // loca,
        zIndex: 113,
        opacity: 1,
        visible: true,
        zooms: [2, 22],
      });
      this.breath.red.setSource(geoLevelF);
      this.breath.red.setStyle({
        unit: "meter",
        size: [70, 70],
        borderWidth: 0,
        texture:
          "https://a.amap.com/Loca/static/loca-v2/demos/images/breath_red.png",
        duration: 800,
        animate: true,
      });
      // this.loca.add(breathRed);
    },
    // 黄色呼吸点
    addYellowPoint() {
      this.geoData.geoYellow = this.geoData.all.filter((item) => {
        return item.state == 1;
      });

      let geoYellowjson = {
        type: "FeatureCollection",
        features: this.geoData.geoYellow,
      };
      // 黄色呼吸点
      var geoLevelE = new Loca.GeoJSONSource({
        data: geoYellowjson,
      });
      this.breath.yellow = new Loca.ScatterLayer({
        // loca,
        zIndex: 112,
        opacity: 1,
        visible: true,
        zooms: [2, 22],
      });
      this.breath.yellow.setSource(geoLevelE);
      this.breath.yellow.setStyle({
        unit: "meter",
        size: [50, 50],
        borderWidth: 0,
        texture:
          "https://a.amap.com/Loca/static/loca-v2/demos/images/breath_yellow.png",
        duration: 1000,
        animate: true,
      });
      // this.loca.add(breathYellow);
    },

    //切换企业类型
    btnClick(t) {
      // this.geoData.data = [];
      switch (t) {
        case 0:
          this.setMapData(this.geoData.geoRed);
          break;
        case 1:
          this.setMapData(this.geoData.geoYellow);
          break;
        case 2:
          this.setMapData(this.geoData.geoGreen);
          break;
        default: {
          this.setMapData(this.geoData.all);
          break;
        }
      }
    },

    openProjectDetail(id) {
      this.$modal.show(
        Camera, //组件引用页面
        { Companyid: id }, //入参值
        { height: "auto", width: 600, draggable: true } //跳出框参数
      );
      // this.$router.push({ path: "/Test?id=" + id });
    },
  },
};
</script>
<style>
#container {
  left: 0px;
  top: 0px;
}
/* 去除高德地图logo */
.amap-logo {
  display: none;
  opacity: 0 !important;
}
.amap-copyright {
  opacity: 0;
}
</style>
